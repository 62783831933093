<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="range"
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="field"
        v-model="formattedDate"
        label="Range Tanggal"
        prepend-inner-icon="mdi-calendar"
        hide-details="auto"
        outlined
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>

    <v-date-picker
      ref="datePicker"
      v-model="range"
      :value="value"
      color="primary"
      header-color="dark"
      scrollable
      range
      @change="change"
    ></v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: null,
    },
  },

  data: () => ({
    modal: null,
    range: null,
    formattedDate: null,
  }),

  created() {
    if (this.value) {
      this.range = this.value

      return
    }

    const date = new Date()

    const start = this.$moment(
      `${date.getFullYear()}-${date.getMonth() + 1}-01`
    ).format('Y-MM-DD')
    const end = this.$moment().format('Y-MM-DD')

    this.range = [start, end]
    this.$emit('input', this.range)
    this.formattedDate = this.formatRangeString(this.range)
  },

  methods: {
    change() {
      this.$refs.dialog.save(this.range)
      this.$emit('input', this.range)
      this.formattedDate = this.formatRangeString(this.range)
    },

    formatRangeString(range) {
      const start = this.$moment(range[0]).format('DD/MM/Y')
      const end = this.$moment(range[1]).format('DD/MM/Y')

      return `${start} - ${end}`
    },
  },
}
</script>
