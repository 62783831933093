<template>
  <div class="salesman-details">
    <v-card class="mb-2">
      <v-card-title> Laporan Salesman </v-card-title>

      <v-card-text v-text="salesman.data.salesman_name"></v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <date-range-picker v-model="filter.dateRange"></date-range-picker>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" color="#15263b">
      <v-card-title class="pb-1"> Net Sales </v-card-title>

      <v-card-text>
        <h2 v-text="salesmans.sales"></h2>
      </v-card-text>
    </v-card>

    <v-card v-if="!isSalesman" class="mb-2">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>HPP</td>
                <td v-text="salesmans.cost" class="text-right"></td>
              </tr>
              <tr>
                <td>Gross Profit</td>
                <td v-text="salesmans.profit" class="text-right"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        filled
        hide-details
        @input="getData"
      ></v-text-field>

      <v-data-table
        :headers="salesmans.headers"
        :items="salesmans.data"
        :loading="salesmans.loading"
        :options.sync="options"
        :server-items-length="salesmans.count"
        class="elevation-1"
      >
        <template #[`item.actions`]="{ item }">
          <v-btn color="light-blue" small @click="openDetails(item.trans_no)">
            Details
          </v-btn>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
import DateRangePicker from '@/components/Pickers/DateRangePicker.vue'

export default {
  name: 'SalesmanDetails',

  components: { DateRangePicker },

  data() {
    let isSalesman = this.getSalesmanStatus()
    let headers = [
      { text: 'No Transaksi', value: 'trans_no' },
      { text: 'Referensi', value: 'reference' },
      { text: 'Customer', value: 'br_name' },
      { text: 'Tanggal', value: 'tran_date' },
      { text: 'HPP', value: 'cost' },
      { text: 'Net Sales', value: 'sales' },
      { text: 'Gross Profit', value: 'profit' },
      { text: '', sortable: false, value: 'actions' },
    ]

    if (isSalesman) {
      headers = [
        { text: 'No Transaksi', value: 'trans_no' },
        { text: 'Referensi', value: 'reference' },
        { text: 'Customer', value: 'br_name' },
        { text: 'Tanggal', value: 'tran_date' },
        { text: 'Net Sales', value: 'sales' },
        { text: '', sortable: false, value: 'actions' },
      ]
    }

    return {
      filter: {
        dateRange: null,
      },

      salesmans: {
        count: 0,

        cost: 0,

        data: [],

        headers: headers,

        loading: false,

        sales: 0,

        profit: 0,

        total: 0,
      },

      options: {},

      search: '',

      salesman: {
        data: {},

        loading: false,
      },

      salesmanCode: null,
    }
  },

  computed: {
    isSalesman() {
      return this.getSalesmanStatus()
    },
  },

  watch: {
    options: {
      deep: true,

      handler() {
        this.getData()
      },
    },

    'filter.dateRange'() {
      this.getData()
    },
  },

  mounted() {
    this.salesmanCode = this.$route.params.salesmanCode

    this.getSalesman()

    this.getData()
  },

  methods: {
    getData() {
      if (!this.filter.dateRange) return

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.salesmans.data = []
      this.salesmans.loading = true

      this.$http
        .get('/salesmans/' + this.salesmanCode + '/report', {
          params: {
            search: this.search,
            sortBy: sortBy[0] ? sortBy[0] : '',
            sortDesc: sortDesc[0] ? sortDesc[0] : '',
            page: page,
            itemsPerPage: itemsPerPage,
            startDate: this.filter.dateRange ? this.date2sql(this.filter.dateRange[0]) : '',
            endDate: this.filter.dateRange ? this.date2sql(this.filter.dateRange[1]) : '',
          },
        })
        .then(({ data }) => {
          this.salesmans.count = data.count

          data.result.forEach((payment) => {
            payment.tran_date = this.sql2date(payment.tran_date)
          })

          this.salesmans.data = data.result
          this.salesmans.cost = data.cost
          this.salesmans.sales = data.sales
          this.salesmans.profit = data.profit
          this.salesmans.loading = false
        })
    },

    getSalesman() {
      this.salesman.loading = false

      this.$http.get('/salesmans/' + this.salesmanCode).then((result) => {
        this.salesman.data = result.data
        this.salesman.loading = false
      })
    },

    openDetails(transNo) {
      this.$router.push('/sales/invoices/' + transNo)
    },
  },
}
</script>
